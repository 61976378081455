<template>
	<div>
		<banner-m >
			<img src="/banner/m/gongsijj.png" alt="" style="width: 100%;" @click="tg('https://kefu.51xd.cn/register')">
		</banner-m>
		<div class="f-content">
			<p>公司简介</p>
			<div class="tt"><img src="../../assets/svg/gsjs.svg" />公司介绍</div>
			<div>
				小到团队深耕营销型客服领域十余年，拥有优秀的算法与工程师团队，专注提升 中小企业营销转化效果，是国内先进的人工智能整体解决方案提供商。 
			</div>
			<div style="margin-top: 0.08rem;margin-bottom: 0.24rem;">
				小到AI客服依托情感计算、自然语言处理、深度学习、知识工程、文本处理等人 工智能技术为基础，将先进的认知计算技术赋能教育培训、民营医疗、招商加盟、家装、电商服务等垂直领域，帮助中小企业进行数字化智能化营销转型，提升行业效率， 降低人力成本并挖掘更多客户价值。 
			</div>
			
			<div class="tt"><img src="../../assets/svg/wmdsm.svg" />我们的使命</div>
			<div style="margin-bottom: 0.24rem;">
				持续推动世界向前,我们希望聚集最优秀的人才，致力于追求卓越、持续创新、解决问题、创造价值,为世界的发展探寻正确的方向，成为驱动前进的力量。 
			</div>
			
			<div class="tt"><img src="../../assets/svg/fzdx.svg" />发展导向</div>
			<div style="margin-bottom: 0.24rem;">
				随着技术与需求的提高，我们在不断听取市场与用户的反馈并整合共性问题，意在让产品更加符合实际需求。未来，小到团队会在“AI客服系统”的基础之上持续发力，对市场深耕细作，用优秀的技术与我们的客户一道创造更加可观的价值。
			</div>
			
			
			<div class="tt"><img src="../../assets/svg/fwbz.svg" />服务保障</div>
			<div style="margin-bottom: 0.24rem;">
				小到团队始终坚持为合作伙伴提供高效的客户服务支持，拥有强大完备的售后服务体系，以300余人的专业售后服务团队向客户提供"7×24"的可靠服务，帮助他们高效、及时的解决问题。
			</div>
			<img src="@/assets/gsjjtp@2x.png"  style="width: 100%;">
		</div>
		
		<div class="f5">
					<div class="f-content">
						<p>公司地址</p>
						<div style="width: 3.43rem;	height: 1.94rem;">
							<g-map />
						</div>
					</div>
				</div>
		<footer-m nos="11"/>
	</div>

</template>

<script>
	import gMap from '@/components/map.vue'
	export default{
		components:{ gMap }
	}
</script>

<style lang="scss" scoped>
	.tt{
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 0.16rem;
		line-height: 0.24rem;
		color: #182F50;
		margin-bottom: 0.12rem;
		img{
			margin-right: 0.15rem;
		}
		&~div{
			font-weight: 400;
			font-size: 0.14rem;
			line-height: 0.24rem;
			color: #60666F;
		}
	}
</style>